<template>
  <!-- 渠道注册表单-组件 -->
  <div class="partner-form">
    <!-- 标题 -->
    <div class="form-titles" v-if="showTitle">
      <p v-for="(item, index) in titles" :key="index" v-text="item" />
    </div>

    <!-- 表单 -->
    <div class="form-container">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="80px"
        label-position="top"
      >
        <el-form-item label="姓名" prop="name">
          <el-input v-model.trim="form.name"></el-input>
        </el-form-item>
        <el-form-item v-if="showMobile" label="手机号码" prop="mobile">
          <el-input v-model.trim="form.mobile"></el-input>
        </el-form-item>
        <el-form-item label="邮箱地址" prop="email">
          <el-input v-model.trim="form.email"></el-input>
        </el-form-item>
        <el-form-item label="公司名称或学校名称" prop="company">
          <el-input v-model.trim="form.company"></el-input>
        </el-form-item>
        <el-form-item label="部门" prop="department">
          <el-input v-model.trim="form.department"></el-input>
        </el-form-item>
        <el-form-item label="职位" prop="position">
          <el-input v-model.trim="form.position"></el-input>
        </el-form-item>
        <el-form-item label="行业" prop="industry">
          <el-input v-model.trim="form.industry"></el-input>
        </el-form-item>
        <el-form-item label="公司网址" prop="companyWebsite">
          <el-input v-model.trim="form.companyWebsite"></el-input>
        </el-form-item>
        <el-form-item
          label="您的问题或需求"
          v-if="showIssue"
          style="width:100%"
          prop="issue"
        >
          <el-input
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 4 }"
            placeholder="请输入您的问题或需求"
            v-model="form.issue"
          >
          </el-input>
        </el-form-item>
      </el-form>

      <div class="btns">
        <el-button v-if="showBack" type="success" @click="$emit('goBack')"
          >上一步</el-button
        >
        <el-button plain class="subBtn" @click="submit"
          >提交申请 <i class="el-icon-caret-right"
        /></el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "partnerForm",
  props: {
    titles: {
      type: Array,
      default() {
        return [
          "我们非常重视合作伙伴关系,并制定了优厚的回馈计划以鼓励和支持您的选择。",
          "如果您有任何关于合作的想法或问题,请致电020-32285850,或者发邮件至service@refinance.com.cn,与我们联系",
        ];
      },
    },
    showMobile: {
      type: Boolean,
      default: true,
    },
    showIssue: {
      type: Boolean,
      default: true,
    },
    showBack: {
      type: Boolean,
      default: false,
    }, // 显示返回
    showTitle: {
      type: Boolean,
      default: true,
    }, // 是否显示标题
    form: {
      type: Object,
      default() {
        return {};
      },
    }, // 表单数据
  },
  data() {
    return {
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        company: [
          {
            required: true,
            message: "请输入公司或学校名称",
            trigger: "change",
          },
        ],
        mobile: [
          {
            required: true,
            message: "请输入手机号码",
            trigger: "blur",
          },
          {
            pattern: /^(?:(?:\+|00)86)?1\d{10}$/,
            message: "请输入正确的手机号码",
            trigger: "change",
          },
        ],
        department: [
          {
            required: true,
            message: "请输入部门",
            trigger: "blur",
          },
        ],
        position: [
          {
            required: true,
            message: "请输入职业",
            trigger: "blur",
          },
        ],
        industry: [
          {
            required: true,
            message: "请输入行业",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "请输入邮箱地址",
            trigger: "blur",
          },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: "change",
          },
        ],
      }, // 校验规则
    };
  },
  methods: {
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          console.log("提交", this.form);
          this.$emit("validSuccess", this.form);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.partner-form {
  width: 1000px;
  margin: 0 auto;
  background-color: #fff;

  // 表单标题
  .form-titles {
    font-size: 14px;
    margin: 35px 0 15px;
    line-height: 24px;
    color: var(--main-memo_gray);
  }

  // 表单容器
  .form-container {
    text-align: left;
    padding: 30px 50px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    // border: 1px solid var(--border-color);

    .el-form {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .el-form-item {
        margin-bottom: 20px;
        width: 320px;
        text-align: left;

        /deep/ .el-form-item__label {
          line-height: 1;
        }
      }
    }

    .subBtn.is-plain {
      width: 180px;
      border-color: var(--button-color_blue);
      color: var(--button-color_blue);
    }
  }
}
</style>
