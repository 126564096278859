<template>
  <!-- 合作伙伴 -->
  <div>
    <!-- 产品介绍 -->
    <product-brief
      height="340"
      :title="currentData && currentData.productBrief.title"
      :memo="currentData && currentData.productBrief.memo"
      :img-src="currentData && currentData.productBrief.imgSrc"
    />

    <!-- 产品概述 -->
    <product
      :productData="currentData && currentData.productData"
      :dot="currentData && currentData.productData.dot"
    />

    <!-- 产品详细介绍-横幅 -->
    <horizontal-bar
      v-for="(item, index) in currentData && currentData.horizontalBars"
      :key="index"
      :grayTitle="item.grayTitle"
      :title="item.title"
      :subTitle="item.subTitle"
      :barStyle="item.style"
    >
      <!-- 流程进度条 -->
      <process-bar v-if="item.processBar" :progressData="item.processBar" />

      <!-- 渠道代理表单 -->
      <partner-form
        ref="agentForm"
        v-if="item.partnerForm"
        :form="form"
        @validSuccess="validSuccess"
      />
    </horizontal-bar>
  </div>
</template>

<script>
import processBar from "./components/process-bar";
import partnerForm from "@/components/local/partner-form";

import { addChannelAgents } from "@/api/partner";

export default {
  components: {
    processBar, // 流程进度-组件
    partnerForm, // 渠道注册表单-组件
  },
  data() {
    return {
      currentData: {
        productBrief: {
          title: "合作伙伴",
          memo: [
            "致力于建设战略合作联盟,共同为金融科技行业提供领先的解决方案组合,帮助客户建立更加系统、完善的业务解决方案",
          ],
          imgSrc: "https://s1.ax1x.com/2020/07/27/aPDj8x.png",
        }, // 产品介绍组件数据
        productData: {
          imgSrc: "https://s1.ax1x.com/2020/07/27/aPDL5R.png",
          name: "合作带来更大可能",
          enName: "Cooperation Brings Greater Possibilities",
          memos: [
            "广州瑞友在快速发展过程中,将战略合作联盟视为业务持续发展的重要基础。紧密的合作将实现优势互补,并有利于技术与产品创新,形成卓越的产品服务组合,满足客户的多样化需求,共同为客户打造极致的用户体验和系统的解决方案,从而一起实现产品价值的飞跃提升。",
          ],
        }, // 产品概述组件数据
        horizontalBars: [
          {
            grayTitle: true,
            title: "合作流程",
            subTitle: "Cooperation Process",
            style: {
              background:
                '#f6f7f7 url("https://s1.ax1x.com/2020/07/27/aPDv26.png") no-repeat',
            },
            processBar: [
              {
                percent: 20,
                textNum: "01",
                text: "提交申请",
                extra: "申请审核通过",
              },
              {
                percent: 40,
                textNum: "02",
                text: "合作洽谈",
              },
              {
                percent: 60,
                textNum: "03",
                text: "协议签订",
                extra: "业务发布",
              },
              {
                percent: 80,
                textNum: "04",
                text: "项目实施",
              },
              {
                percent: 100,
                textNum: "05",
                text: "产品赋能",
              },
            ],
          },
          {
            grayTitle: true,
            title: "渠道代理",
            subTitle: "Channel Agent",
            partnerForm: true,
          },
        ], // 横幅内容
      },
      form: {},
    };
  },
  created() {
    let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    this.form = {
      ...userInfo,
      createdAt: null,
      updatedAt: null,
      state: null,
      target: this.$route.query?.fileName,
      mobile: userInfo.mobile.replace("+86", ""),
    };
  },
  methods: {
    validSuccess(form) {
      console.log("验证通过", form);
      addChannelAgents(form).then((res) => {
        this.$notify.success("申请成功");
        this.$refs.agentForm[0].$refs.form.resetFields(); // 重置表单
      });
      // if (!this.$store.state.isLogin) {
      //   this.$store.commit("changeShowLogin", true);
      // } else {
      // }
    }, // 验证通过
  },
};
</script>

<style></style>
