<template>
  <!-- 流程进度条-组件 -->
  <div class="process-bar">
    <div
      class="process-items"
      v-for="(item, index) in progressData"
      :key="index"
    >
      <div class="process-item">
        <a-progress
          type="circle"
          :percent="item.incPercent"
          :width="80"
          :strokeWidth="10"
          :stroke-color="{
            '0%': '#70ddfb',
            '100%': '#468af7',
          }"
          :format="() => item.textNum"
        />

        <div class="vertical-line" v-if="item.extra">
          <div class="line" />
          <p class="line-text" v-text="item.extra" />
        </div>
      </div>

      <p class="process-memo" v-text="item.text" />
    </div>
  </div>
</template>

<script>
export default {
  name: "processBar",
  props: {
    progressData: {
      type: Array,
      default() {
        return [
          {
            percent: 20,
            textNum: "01",
            text: "进度1",
            extra: "进度审核1",
          },
          {
            percent: 40,
            textNum: "02",
            text: "进度2",
          },
          {
            percent: 60,
            textNum: "03",
            text: "进度3",
            extra: "进度发布3",
          },
          {
            percent: 80,
            textNum: "04",
            text: "进度4",
          },
          {
            percent: 100,
            textNum: "05",
            text: "进度5",
          },
        ];
      },
    }, // 进度条数据
  },
  data() {
    return {};
  },
  created() {
    this.percentAnimate();
  },
  methods: {
    percentAnimate() {
      for (let index = 0; index < this.progressData.length; index++) {
        const item = this.progressData[index];
        this.$set(item, "incPercent", 0);
        let timer = setInterval(() => {
          item.incPercent >= item.percent
            ? clearInterval(timer)
            : item.incPercent++;
        }, 16);
      }
    }, // 进度自增动画
  },
};
</script>

<style lang="less" scoped>
.process-bar {
  position: relative;
  width: 1200px;
  padding: 70px 0;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  // 主横线
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    top: 110px;
    left: 0;
    background-color: var(--border-color);
  }

  .process-items {
    .process-item {
      position: relative;

      /deep/ .ant-progress-inner.ant-progress-circle-gradient {
        background: #fff;
      }

      /deep/ .ant-progress-circle .ant-progress-text {
        color: #000;
        font-size: 16px;
      }

      // 竖线-文字
      .vertical-line {
        position: absolute;
        left: 120px;
        top: 45px;
        display: flex;
        flex-direction: column;
        align-items: center;

        // border自定义虚线
        .line {
          position: relative;
          width: 2px;
          height: 86px;
          border-left: 1px dashed transparent;
          background: linear-gradient(transparent, transparent) padding-box,
            repeating-linear-gradient(
              -45deg,
              var(--border-color) 0,
              var(--border-color) 0.35em,
              transparent 0,
              transparent 0.65em
            );

          // 圆点
          &::before,
          &::after {
            content: "";
            position: absolute;
            top: -8px;
            left: -4px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: var(--border-color);
          }

          &::after {
            width: 6px;
            height: 6px;
            top: auto;
            left: -3px;
            bottom: -8px;
          }
        }

        .line-text {
          width: 100px;
          line-height: 30px;
          font-size: 14px;
          color: #696969;
          margin-top: 20px;
          margin-bottom: 0;
          border-radius: 3px;
          background: #e8e8e8;
        }
      }
    }

    .process-memo {
      margin-top: 20px;
      font-size: 20px;
      color: #1890ff;
    }
  }
}
</style>
