// 合作伙伴

// 渠道代理申请
export function addChannelAgents(data) {
  return axios({
    url: `/api/channel-agents`,
    method: "post",
    data,
  });
}
